.App {
  width: 80%;
  margin: 0 auto;
  color: #333;
}

.App-Logo img{
  max-width: 7em;
}

.Insta-Logo img {
  max-width: 2em;
  margin-right: 1em;
}

.Tiktok-Logo img {
  max-width: 2em;
  margin-right: 1em;
}

.Docto-Logo img {
  max-width: 2em;
}

.FB-Logo img {
  max-width: 2.3em;
  margin-right: 1em;
}

.FB-Logo, .Insta-Logo, .Tiktok-Logo, .Docto-Logo {
  z-index: 2;
  cursor: pointer;
}

@media (max-width: 45.5em) {
  .App {
    width: 90%;
  }

  .App-Logo, .Insta-Logo {
    top: 12px;
  }

  .App-Logo, .RS-Logos {
    display: none;
  }

}
