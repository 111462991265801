/*@font-face {
	font-family: 'Geometria';
	src: url('../../fonts/Geometria.woff');
}*/


@keyframes slidein {
    from {
        margin-left: -50%;
        visibility: hidden;
    }
  
    to {
        margin-left: 0%;
        visibility: visible;
    }
  }

@keyframes slideout {
from {
    margin-left: 0%;
    visibility: visible;
}

to {
    margin-left: -50%;
    visibility: hidden;
}
}

@keyframes opacity {
    from {
        opacity: 1;
    }

    to {
        opacity: 0.5;
    }
    }

.Nav {
    display: flex;
    flex-direction: row;
    padding: 0em 0em 4em 0em;
    align-items: center;
}

#SubNav {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 1;
    background-color: white;
    padding: 0.5em 0em 0em 0em;
    margin-bottom: 3em;
}

.MainNav::after {
    box-shadow: 0 0 5px rgb(0 0 0 / 30%);
    content:'';
    height: 120px;
    width: -webkit-fill-available;
    left: 0;
    right: 0;
    position: absolute;
}

.MSS_Logo {
    z-index: 2;
    cursor: pointer;
}

.Nav-item {
    padding: 1em;
    color: #0076b1;
    text-align: center;
    font-family: 'Geometria', sans-serif;
    letter-spacing: 2px;
    font-weight: 600;
    font-size: 0.65em;
    height: 0.8em;
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
    z-index: 2;
    position: relative;
}

.Nav-Core {
    margin: auto;
    display: flex;
}

.SubNav::after {
    box-shadow: 0 10px 5px -10px rgb(0 0 0 / 30%);
    content: '';
    height: 40px;
    width: -webkit-fill-available;
    left: 0;
    right: 0;
    position: absolute;
}

.Nav-Core.SubNav .Nav-item {
    color: #333;
}

.Nav-Core.SubNav .Nav-item.active {
    height: 2.08em;
}

.Nav-Core.SubNav .Nav-item {
    font-size: 0.6em;
}

.Nav-item:hover::before {
    content: "";
    border-bottom: 2px solid #ef7e2d;
    position: absolute;
    width: 100%;
    left: 0;
    padding-bottom: 20px;
}

.Nav-Core.SubNav .Nav-item:hover::before {
    padding-bottom: 30px;
}

.Nav-item:hover {
    animation-name: opacity;
    animation-duration: 1s;
    opacity: 0.5;
}

.Nav-item.active {
    opacity: 0.5;
    border-bottom: 2px solid #ef7e2d;
    height: 0.9em;
}

/* Mobile */

@media (max-width: 45.5em) {
    .Nav {
        display: inline-block;
        width: 80%;
        padding: 0;
    }

    .Nav-hide #Nav-Links {
        visibility: hidden;
    }

    #SubNav {
        display: none;
    }

    .MSS_Logo .App-Logo {
        display: block;
        position: absolute;
        right: 15px;
    }

    .MSS_Logo .App-Logo img {
        max-width: 2.7em;
    }

    #Nav-mobile-menu {
        padding-top: 4em;
        position: relative;
        width: 100%;
        z-index: 2;
        padding-bottom: 1em;
    }

    .Nav-mobile-menu-icon:before {
        content: '';
        position: absolute;
        top: 1.2em;
        background-size: 25px;
        left: 0;
        display: block;
        width: 100%;
        height: 3em;
        background-image: url("../../images/burger.png");
        background-repeat: no-repeat;
        z-index: 2;
    }

    .Nav-item {
        width: 100%;
        padding: 1em 0;
        flex: none;
        display: inline-block;
        text-align: left;
        color: #0076b1;
        font-size: 0.85em;
    }

    .Nav-item:hover::before {
        border: none;
    }

    .moved {
        animation-name: slidein;
        animation-duration: 1.2s;
    }

    .unmoved {
        animation-name: slideout;
        animation-duration: 1.2s;
    }

    .Nav-hide {
        display: block;
        height: 4em;
    }
}

@media (min-device-width: 768px) and (max-device-width: 1024px) {
    .Nav-item {
        font-size: 0.60em;
    }
}