.MuscuSante-Core {
    
}

.MuscuSante {
    display: block;
    margin-bottom: 4em;
}   

.MuscuSante.MuscuSanteImg {
    margin: auto;
}

.MuscuSante.MuscuSanteImg img{
    max-width: 70%;
    border-radius: 10px;
}

.MuscuSante-SectionTitle {
    color:#ef7e2d;
    font-size: 1.3em;
    font-weight: bold;
    margin-bottom: 1em;
    display: inline-block;
}

.MuscuSante.MuscuSante-Intro {
    font-family: 'Geometria', sans-serif;
    font-size: 0.9em;
    vertical-align: top;
    position: relative;
}

.MuscuSante-Text {
    font-size: 1.1em;
    line-height: 1.5em;
}

.MuscuSante-highlight {
    color: #0076b1;
    font-weight: bold;
}

.MuscuSante-Title {
    font-size: 2em;
    margin-top: 0;
    margin-bottom: 1.5em;
    color: #ef7e2d;
}

.MuscuSante-Title::after {
    content: '';
    position: absolute;
    width: 50px;
    height: 1.5em;
    border-bottom: 2px solid #ef7e2d;
    left: 0;
}

#sport-sante ul li::marker, #sport-alimentation ul li::marker, #jardiner-sante ul li::marker{
    color: #ef7e2d;
}

.MuscuSante-Core img {
    max-width: 100%;
    border-radius: 10px;
    margin: 30px 0;
}

.MuscuSante-Twoimg, .MuscuSante-Twoimg-variante {
    display: flex;
    justify-content:space-evenly;
    flex-flow: wrap;
}

.MuscuSante-Twoimg > img {
    max-width: 40%;
    flex: 0 1 auto;
}

.MuscuSante-Twoimg-variante > img {
    max-width: 47%;
    max-height: 400px;
}

@media (max-width: 45.5em) {
    .MuscuSante-Core {
        flex-direction: column-reverse;
        margin-bottom: 0;
    }
    
    .MuscuSante-Title {
        margin-top: 0.5em;
        font-size: 1.7em
    }
}