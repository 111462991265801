.Adhesions-Core {
    margin-bottom: 3em;
}

.Adhesions {
    display: block;
}   

.Adhesions-Intro {
    padding-top: 3em;
}

.Adhesions-Text {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: 3em;
    align-items: center;
    box-shadow: 0 10px 5px -11px rgb(0 0 0 / 30%);
}

.Adhesions-Text.Adhesions-LastText {
    box-shadow: none;
    margin-bottom: 30em;
}

.Adhesions-Right, .Adhesions-Left {
    flex: 1 1;
}  

.Adhesions-subtitle {
    color: #0076b1;
}

.Adhesions-mention {
    font-size: 0.8em;
    font-weight: bold;
}

.Adhesions-Left {
    padding-right: 2em;
}

.Adhesions-LeftTrue {
    border-right: 2px solid #ef7e2d;
}

.Adhesions-RightTrue {
    border-Left: 2px solid #ef7e2d;
}

.Adhesions-Right {
    padding-left: 3em;
}

.Adhesions.AdhesionsImg {
    margin: auto;
}

.Adhesions.AdhesionsImg img{
    max-width: 70%;
    border-radius: 10px;
}

.Adhesions.Adhesions-Intro {
    font-family: 'Geometria', sans-serif;
    font-size: 0.9em;
    vertical-align: top;
    position: relative;
}

.Adhesions-Text {
    font-size: 1.1em;
    line-height: 1.5em;
}

.Adhesions-Title {
    font-size: 2em;
    margin-top: 0;
    margin-bottom: 1.5em;
    color: #ef7e2d;
}

.Adhesions-Title::after {
    content: '';
    position: absolute;
    width: 50px;
    height: 1.5em;
    border-bottom: 2px solid #ef7e2d;
    left: 0;
}

@media (max-width: 45.5em) {
    .Adhesions-Core {
        flex-direction: column-reverse;
        margin-bottom: 0;
    }
    
    .Adhesions-Title {
        margin-top: 0.5em;
        font-size: 1.7em;
    }

    .Adhesions-Intro {
        padding-top: 0;
    }

    .Adhesions-Text {
        display: inline-block;
    }

    .Adhesions-RightTrue {
        border-Left: 0;
        border-top: 1px solid #ef7e2d;
        padding-top: 1em;
    }

    .Adhesions-Right {
        padding-left: 0;
        padding-top: 1em;
    }

    .Adhesions-Left {
        padding-right: 2em;
        padding-bottom: 1em;
    }

    .Adhesions-LeftTrue {
        border-bottom: 1px solid #ef7e2d;
        border-right: 0;
    }
    
    .Adhesions-Text.Adhesions-LastText {
        margin-bottom: 0em;
    }
}