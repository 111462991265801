.Accueil-Core {
    font-size: 1em;
    position: relative;
    font-family: 'Geometria', sans-serif;
}

.Accueil-Intro {
    font-size: 1.3em;
    padding-bottom: 0.5em;
    box-shadow: 0 10px 5px -11px rgb(0 0 0 / 30%);
    padding-top: 1.5em;
}

.Accueil-Core img {
    max-width: 100%;
    border-radius: 10px;
    margin: 30px 0;
}

.Accueil-Main-Picture img {
    max-width: 80%;
}

.Accueil-Main-Picture {
    text-align: center;
}

.Accueil-Pictures {
    display: flex;
}

.Accueil-Pictures span {
    max-width: 60%;
    flex: 0 1 auto;
    text-align: center;
    margin: auto;
    font-family: 'Perpetua';
}

.Accueil-Highlights div:nth-child(1),.Accueil-Highlights div:nth-child(2) {
    box-shadow: 0 10px 5px -11px rgb(0 0 0 / 30%);
    padding: 2em;
}

.Accueil-Pictures img {
   max-width: 40%;
}

.Accueil-Pictures span:last-child {
    max-width: 33%;
}   

.Accueil-Highlights {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding-bottom: 5em;
    max-width: 90%;
    margin: auto;
    text-align: center;
}

.Accueil-Highlights div {
    margin-bottom: 1em;
    width: 40%;
    font-size: 1em;
    padding-top: 1.75em;
    margin: auto;
}

.Accueil-Highlights div span {
    font-size: 1.5em;
    padding-bottom: 0.3em;
    display: inline-block;
    color: #ef7e2d;
}

.Accueil-Accroche {
    padding-bottom: 3em;
    width: 80%;
    margin: auto;
    text-align: center;
    font-size: 1.2em;
}

.Accueil-Highlights-Intro {
    color: #0076b1;
    box-shadow: 0 10px 5px -11px rgb(0 0 0 / 30%);
    font-size: 2em;
    text-align: center;
    margin: auto;
    width: 30%;
    padding-bottom: 0.5em;
}

@media (max-width: 45.5em) {
    .Accueil-Pictures {
        display: inline-block;
    }
    .Accueil-Pictures span {
        display: inline-block;
        max-width: 50%;
        padding: 0;
    }

    .Accueil-Pictures span img {
        max-width: 60%;
    }

    .Accueil-Pictures span:last-child {
        max-width: inherit;
    }   

    .Accueil-Pictures .Accueil-Intro {
        padding-bottom: 0.8em;
    }
}