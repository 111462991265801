.Partenaires-Core {
    margin-bottom: 3em;
}

.Partenaires {
    display: block;
}   

.Partenaires_Logos {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    align-items: center;
}

.Partenaires_Logos img {
    flex: 0 1 auto;
    max-width: 9em;
}

.Partenaires.PartenairesImg {
    margin: auto;
}

.Partenaires.PartenairesImg img{
    max-width: 70%;
    border-radius: 10px;
}

.Partenaires.Partenaires-Intro {
    font-family: 'Geometria', sans-serif;
    font-size: 0.9em;
    vertical-align: top;
    position: relative;
}

.Partenaires-Text {
    font-size: 1.1em;
    line-height: 1.5em;
}

.Partenaires-Title {
    font-size: 2em;
    margin-top: 0;
    margin-bottom: 1.5em;
    color: #ef7e2d;
    position: relative;
}

.Partenaires-Title::after {
    content: '';
    position: absolute;
    width: 50px;
    height: 1.5em;
    border-bottom: 2px solid #ef7e2d;
    left: 0;
}

@media (max-width: 45.5em) {
    .Partenaires-Core {
        flex-direction: column-reverse;
        margin-bottom: 0;
    }
    
    .Partenaires-Title {
        margin-top: 0.5em;
        font-size: 1.7em;
    }
}