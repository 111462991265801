.Activites-Photo.ActivitesImg img{
    max-height: 400px;
}

.Activites-Photos {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.Activites-Photo {
    flex: 0 1;
    margin-bottom: 2.2em;
}

.Activites-Photo img {
    border-radius: 10px;
}

.Activites-blue {
    color: #0076b1;
}

.Activites-orange {
    font-weight: bold;
}

.Activites-sms {
    text-decoration: none;
    color: #333;
}

.Activites-Intro {
    font-family: 'Geometria', sans-serif;
    font-size: 0.9em;
    vertical-align: top;
    position: relative;
}

.Activites-Text {
    font-size: 1.1em;
    line-height: 1.5em;
}

.Activites-Title {
    font-size: 2em;
    margin-top: 0;
    margin-bottom: 1.5em;
    color: #ef7e2d;
}

.Activite-MuscuJeuneListe {
    padding: 0 1em;
    margin-top: 0.3em;
}

.Activite-MuscuJeuneListe li {
    font-size: 0.9em;
    line-height: 1.5em;
}

@media (min-device-width: 768px) and (max-device-width: 1024px) {
    .Activites-Photos {
        justify-content: center;
    }
}

@media (max-width: 45.5em) {
    .Activites-Core {
        flex-direction: column-reverse;
    }

    .Activites-Photo {
        flex: 0 1;
        margin: 1em auto;
    }

    .Activites-Photo.ActivitesImg img{
        max-height: 300px;
    }
}

.Activite-Core {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5em;
}

.Activite {
    flex: 0 48%;
    text-align: left;
}   

.Activite.ActiviteImg img{
    max-width: 100%;
}

.Activite.ActiviteImgLeft img {
    border-radius: 0 10px;
}

.Activite.ActiviteImgRight img {
    border-radius: 10px 0;
}

.Activite.Activite-Intro {
    font-family: 'Geometria', sans-serif;
    font-size: 0.9em;
    vertical-align: top;
    position: relative;
}

.Activite-Text {
    font-size: 1.1em;
    line-height: 1.5em;
}

.Activite-Title {
    font-size: 2em;
    margin-top: 0;
    margin-bottom: 1.5em;
    color: #ef7e2d;
}

.Activite-Title::after, .Activites-Title::after {
    content: '';
    position: absolute;
    width: 50px;
    height: 1.5em;
    border-bottom: 2px solid #ef7e2d;
    left: 0;
}

@media (max-width: 45.5em) {
    .Activite-Core {
        flex-direction: column-reverse;
        margin-bottom: 0;
    }

    .Activites-Core {
        margin-top: 1em;
    }
    
    .Activite-Title {
        margin-top: 0.5em;
        font-size: 1.7em;
    }

    .Activites-Title {
        font-size: 1.7em;
    }
}