body {
  margin: 0;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


input[type="password"],
input[type="text"],
select:focus,
textarea {
  font-size: 16px;
}
.App {
  width: 80%;
  margin: 0 auto;
  color: #333;
}

.App-Logo img{
  max-width: 7em;
}

.Insta-Logo img {
  max-width: 2em;
  margin-right: 1em;
}

.Tiktok-Logo img {
  max-width: 2em;
  margin-right: 1em;
}

.Docto-Logo img {
  max-width: 2em;
}

.FB-Logo img {
  max-width: 2.3em;
  margin-right: 1em;
}

.FB-Logo, .Insta-Logo, .Tiktok-Logo, .Docto-Logo {
  z-index: 2;
  cursor: pointer;
}

@media (max-width: 45.5em) {
  .App {
    width: 90%;
  }

  .App-Logo, .Insta-Logo {
    top: 12px;
  }

  .App-Logo, .RS-Logos {
    display: none;
  }

}

.Contact-Core {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3em;
}

.Contact {
    flex: 0 1 48%;
    text-align: left;
    position: relative;
}

.Contact-Intro {
    font-family: 'Geometria', sans-serif;
    font-size: 0.9em;
    vertical-align: top;
    position: relative;
}

.Contact-Title {
    font-size: 2em;
    margin-top: 0;
    margin-bottom: 1.5em;
    color: #ef7e2d;
}

.Contact-Title::after {
    content: '';
    position: absolute;
    width: 50px;
    height: 1.5em;
    border-bottom: 2px solid #ef7e2d;
    left: 0;
}

.Contact-Text {
    font-size: 0.9em;
}

.Contact.Contact-Form {
    font-family: 'Geometria', sans-serif;
    font-size: 0.9em;
    vertical-align: top;
}

.Contact-Telechargements li::marker{
    color: #0076b1;
}

.Contact ul {
    list-style-type: none;
    display: inline-block;
    padding: 0;
    margin: 0em 0;
}

.Contact ul.Contact-mss_corrdonnes li span {
    width: inherit;
    color: #0076b1;
}

.Contact ul.Contact-mss_corrdonnes {
    width: 50%;
    vertical-align: text-top;
}

.Contact ul.Contact-mss_corrdonnes li {
    margin-bottom: 1em;
    font-size: 0.8em;
}

.Contact ul li {
    line-height: 1.8em;
}

.Contact-sms {
    text-decoration: none;
    color: #333;
}

.Contact ul li span {
    width: 7em;
    display: inline-block;
    font-weight: bold;
}

#contactForm span {
    display: inline-block;
    width: 100%;
}

#contactForm .Button {
    border-radius: 5px;
    padding: 4px 10px;
    background-color: white;
}

#contactForm input, #contactForm textarea, #contactForm .Button {
    border: 1px solid #999;
}

.Contact.ContactImg img{
    max-width: 100%;
    border-radius: 10px 0;
}

#submit {
    cursor: pointer;
}

@media (min-device-width: 768px) and (max-device-width: 1024px) {
    .Contact {
        flex: 0 1 100%;
        text-align: left;
    }

    .Contact-Core {
        flex-wrap: wrap;
    }
}

@media (max-width: 45.5em) {
    .Contact-Core {
        flex-direction: column;
    }

    #contactForm input, textarea {
        width: 100%;
        padding: 0;
    }

    .Contact-Title {
        font-size: 1.7em;
    }

    #maps {
        margin-top: 1em;
    }

    .Contact ul.Contact-mss_corrdonnes {
        width: 100%;
    }
}
/*@font-face {
	font-family: 'Geometria';
	src: url('../../fonts/Geometria.woff');
}*/


@-webkit-keyframes slidein {
    from {
        margin-left: -50%;
        visibility: hidden;
    }
  
    to {
        margin-left: 0%;
        visibility: visible;
    }
  }


@keyframes slidein {
    from {
        margin-left: -50%;
        visibility: hidden;
    }
  
    to {
        margin-left: 0%;
        visibility: visible;
    }
  }

@-webkit-keyframes slideout {
from {
    margin-left: 0%;
    visibility: visible;
}

to {
    margin-left: -50%;
    visibility: hidden;
}
}

@keyframes slideout {
from {
    margin-left: 0%;
    visibility: visible;
}

to {
    margin-left: -50%;
    visibility: hidden;
}
}

@-webkit-keyframes opacity {
    from {
        opacity: 1;
    }

    to {
        opacity: 0.5;
    }
    }

@keyframes opacity {
    from {
        opacity: 1;
    }

    to {
        opacity: 0.5;
    }
    }

.Nav {
    display: flex;
    flex-direction: row;
    padding: 0em 0em 4em 0em;
    align-items: center;
}

#SubNav {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 1;
    background-color: white;
    padding: 0.5em 0em 0em 0em;
    margin-bottom: 3em;
}

.MainNav::after {
    box-shadow: 0 0 5px rgb(0 0 0 / 30%);
    content:'';
    height: 120px;
    width: -webkit-fill-available;
    left: 0;
    right: 0;
    position: absolute;
}

.MSS_Logo {
    z-index: 2;
    cursor: pointer;
}

.Nav-item {
    padding: 1em;
    color: #0076b1;
    text-align: center;
    font-family: 'Geometria', sans-serif;
    letter-spacing: 2px;
    font-weight: 600;
    font-size: 0.65em;
    height: 0.8em;
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
    z-index: 2;
    position: relative;
}

.Nav-Core {
    margin: auto;
    display: flex;
}

.SubNav::after {
    box-shadow: 0 10px 5px -10px rgb(0 0 0 / 30%);
    content: '';
    height: 40px;
    width: -webkit-fill-available;
    left: 0;
    right: 0;
    position: absolute;
}

.Nav-Core.SubNav .Nav-item {
    color: #333;
}

.Nav-Core.SubNav .Nav-item.active {
    height: 2.08em;
}

.Nav-Core.SubNav .Nav-item {
    font-size: 0.6em;
}

.Nav-item:hover::before {
    content: "";
    border-bottom: 2px solid #ef7e2d;
    position: absolute;
    width: 100%;
    left: 0;
    padding-bottom: 20px;
}

.Nav-Core.SubNav .Nav-item:hover::before {
    padding-bottom: 30px;
}

.Nav-item:hover {
    -webkit-animation-name: opacity;
            animation-name: opacity;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    opacity: 0.5;
}

.Nav-item.active {
    opacity: 0.5;
    border-bottom: 2px solid #ef7e2d;
    height: 0.9em;
}

/* Mobile */

@media (max-width: 45.5em) {
    .Nav {
        display: inline-block;
        width: 80%;
        padding: 0;
    }

    .Nav-hide #Nav-Links {
        visibility: hidden;
    }

    #SubNav {
        display: none;
    }

    .MSS_Logo .App-Logo {
        display: block;
        position: absolute;
        right: 15px;
    }

    .MSS_Logo .App-Logo img {
        max-width: 2.7em;
    }

    #Nav-mobile-menu {
        padding-top: 4em;
        position: relative;
        width: 100%;
        z-index: 2;
        padding-bottom: 1em;
    }

    .Nav-mobile-menu-icon:before {
        content: '';
        position: absolute;
        top: 1.2em;
        background-size: 25px;
        left: 0;
        display: block;
        width: 100%;
        height: 3em;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAfCAYAAAAbW8YEAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MEUxRUYyNkVBRkUzMTFFRDg3MjhCOEE3NjMyMzg4Q0UiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MEUxRUYyNkZBRkUzMTFFRDg3MjhCOEE3NjMyMzg4Q0UiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDowRTFFRjI2Q0FGRTMxMUVEODcyOEI4QTc2MzIzODhDRSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDowRTFFRjI2REFGRTMxMUVEODcyOEI4QTc2MzIzODhDRSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuA+2KIAAAA+SURBVHja7NRLDQAgDETBgmh8oXCxwIXPYZ6BSZp0W42ZulyvB0GP1pI4LxT6+Z8afKjBh0INvvNCt1sCDADXxQymgEzCZwAAAABJRU5ErkJggg==);
        background-repeat: no-repeat;
        z-index: 2;
    }

    .Nav-item {
        width: 100%;
        padding: 1em 0;
        flex: none;
        display: inline-block;
        text-align: left;
        color: #0076b1;
        font-size: 0.85em;
    }

    .Nav-item:hover::before {
        border: none;
    }

    .moved {
        -webkit-animation-name: slidein;
                animation-name: slidein;
        -webkit-animation-duration: 1.2s;
                animation-duration: 1.2s;
    }

    .unmoved {
        -webkit-animation-name: slideout;
                animation-name: slideout;
        -webkit-animation-duration: 1.2s;
                animation-duration: 1.2s;
    }

    .Nav-hide {
        display: block;
        height: 4em;
    }
}

@media (min-device-width: 768px) and (max-device-width: 1024px) {
    .Nav-item {
        font-size: 0.60em;
    }
}
.MSS-Core {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3em;
}

.MSS {
    flex: 0 1 48%;
    text-align: left;
}   

.MSS.MSSImg img{
    max-width: 100%;
}

.MSS.MSSImgLeft img {
    border-radius: 0 10px;
}

.MSS.MSSImgRight img {
    border-radius: 10px 0;
}

.MSS.MSS-Intro {
    font-family: 'Geometria', sans-serif;
    font-size: 0.9em;
    vertical-align: top;
    position: relative;
}

.MSS-Text {
    font-size: 1.1em;
    line-height: 1.5em;
}

.MSS-Title {
    font-size: 2em;
    margin-top: 0;
    margin-bottom: 1.5em;
    color: #ef7e2d;
}

.MSS-ImgCentered {
    text-align: center;
}   

.MSS-ImgCentered img {
    width: 100%;
    border-radius: 10px;
}

.MSS-Title::after {
    content: '';
    position: absolute;
    width: 50px;
    height: 1.5em;
    border-bottom: 2px solid #ef7e2d;
    left: 0;
}

.MSS-SubTitle {
    color: #0076b1;
    font-weight: bold;
    font-family: 'Geometria', sans-serif;
    font-size: 14px;
    margin-top:3em;
    margin-bottom: 0.5em;
    padding: 0;
}

.MSS-SubTitle + p {
    font-family: 'Geometria', sans-serif;
    font-size: 12px;
    padding: 0;
    margin: 0;
    line-height: 1.5em;
}

#team {
    margin-bottom: 0;
    padding-bottom: 0;
}

.MSS-Intro + .MSS-SubTitle {
    margin-top: 0;
}

@media (max-width: 45.5em) {
    .MSS-Core {
        flex-direction: column-reverse;
        margin-bottom: 0;
    }
    
    .MSS-Title {
        margin-top: 0.5em;
        font-size: 1.7em;
    }

    #maison + .MSSImgRight {
        margin-top: 1em;
    }
}
.Activites-Photo.ActivitesImg img{
    max-height: 400px;
}

.Activites-Photos {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.Activites-Photo {
    flex: 0 1;
    margin-bottom: 2.2em;
}

.Activites-Photo img {
    border-radius: 10px;
}

.Activites-blue {
    color: #0076b1;
}

.Activites-orange {
    font-weight: bold;
}

.Activites-sms {
    text-decoration: none;
    color: #333;
}

.Activites-Intro {
    font-family: 'Geometria', sans-serif;
    font-size: 0.9em;
    vertical-align: top;
    position: relative;
}

.Activites-Text {
    font-size: 1.1em;
    line-height: 1.5em;
}

.Activites-Title {
    font-size: 2em;
    margin-top: 0;
    margin-bottom: 1.5em;
    color: #ef7e2d;
}

.Activite-MuscuJeuneListe {
    padding: 0 1em;
    margin-top: 0.3em;
}

.Activite-MuscuJeuneListe li {
    font-size: 0.9em;
    line-height: 1.5em;
}

@media (min-device-width: 768px) and (max-device-width: 1024px) {
    .Activites-Photos {
        justify-content: center;
    }
}

@media (max-width: 45.5em) {
    .Activites-Core {
        flex-direction: column-reverse;
    }

    .Activites-Photo {
        flex: 0 1;
        margin: 1em auto;
    }

    .Activites-Photo.ActivitesImg img{
        max-height: 300px;
    }
}

.Activite-Core {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5em;
}

.Activite {
    flex: 0 1 48%;
    text-align: left;
}   

.Activite.ActiviteImg img{
    max-width: 100%;
}

.Activite.ActiviteImgLeft img {
    border-radius: 0 10px;
}

.Activite.ActiviteImgRight img {
    border-radius: 10px 0;
}

.Activite.Activite-Intro {
    font-family: 'Geometria', sans-serif;
    font-size: 0.9em;
    vertical-align: top;
    position: relative;
}

.Activite-Text {
    font-size: 1.1em;
    line-height: 1.5em;
}

.Activite-Title {
    font-size: 2em;
    margin-top: 0;
    margin-bottom: 1.5em;
    color: #ef7e2d;
}

.Activite-Title::after, .Activites-Title::after {
    content: '';
    position: absolute;
    width: 50px;
    height: 1.5em;
    border-bottom: 2px solid #ef7e2d;
    left: 0;
}

@media (max-width: 45.5em) {
    .Activite-Core {
        flex-direction: column-reverse;
        margin-bottom: 0;
    }

    .Activites-Core {
        margin-top: 1em;
    }
    
    .Activite-Title {
        margin-top: 0.5em;
        font-size: 1.7em;
    }

    .Activites-Title {
        font-size: 1.7em;
    }
}
.Partenaires-Core {
    margin-bottom: 3em;
}

.Partenaires {
    display: block;
}   

.Partenaires_Logos {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    align-items: center;
}

.Partenaires_Logos img {
    flex: 0 1 auto;
    max-width: 9em;
}

.Partenaires.PartenairesImg {
    margin: auto;
}

.Partenaires.PartenairesImg img{
    max-width: 70%;
    border-radius: 10px;
}

.Partenaires.Partenaires-Intro {
    font-family: 'Geometria', sans-serif;
    font-size: 0.9em;
    vertical-align: top;
    position: relative;
}

.Partenaires-Text {
    font-size: 1.1em;
    line-height: 1.5em;
}

.Partenaires-Title {
    font-size: 2em;
    margin-top: 0;
    margin-bottom: 1.5em;
    color: #ef7e2d;
    position: relative;
}

.Partenaires-Title::after {
    content: '';
    position: absolute;
    width: 50px;
    height: 1.5em;
    border-bottom: 2px solid #ef7e2d;
    left: 0;
}

@media (max-width: 45.5em) {
    .Partenaires-Core {
        flex-direction: column-reverse;
        margin-bottom: 0;
    }
    
    .Partenaires-Title {
        margin-top: 0.5em;
        font-size: 1.7em;
    }
}
.Adhesions-Core {
    margin-bottom: 3em;
}

.Adhesions {
    display: block;
}   

.Adhesions-Intro {
    padding-top: 3em;
}

.Adhesions-Text {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: 3em;
    align-items: center;
    box-shadow: 0 10px 5px -11px rgb(0 0 0 / 30%);
}

.Adhesions-Text.Adhesions-LastText {
    box-shadow: none;
    margin-bottom: 30em;
}

.Adhesions-Right, .Adhesions-Left {
    flex: 1 1;
}  

.Adhesions-subtitle {
    color: #0076b1;
}

.Adhesions-mention {
    font-size: 0.8em;
    font-weight: bold;
}

.Adhesions-Left {
    padding-right: 2em;
}

.Adhesions-LeftTrue {
    border-right: 2px solid #ef7e2d;
}

.Adhesions-RightTrue {
    border-Left: 2px solid #ef7e2d;
}

.Adhesions-Right {
    padding-left: 3em;
}

.Adhesions.AdhesionsImg {
    margin: auto;
}

.Adhesions.AdhesionsImg img{
    max-width: 70%;
    border-radius: 10px;
}

.Adhesions.Adhesions-Intro {
    font-family: 'Geometria', sans-serif;
    font-size: 0.9em;
    vertical-align: top;
    position: relative;
}

.Adhesions-Text {
    font-size: 1.1em;
    line-height: 1.5em;
}

.Adhesions-Title {
    font-size: 2em;
    margin-top: 0;
    margin-bottom: 1.5em;
    color: #ef7e2d;
}

.Adhesions-Title::after {
    content: '';
    position: absolute;
    width: 50px;
    height: 1.5em;
    border-bottom: 2px solid #ef7e2d;
    left: 0;
}

@media (max-width: 45.5em) {
    .Adhesions-Core {
        flex-direction: column-reverse;
        margin-bottom: 0;
    }
    
    .Adhesions-Title {
        margin-top: 0.5em;
        font-size: 1.7em;
    }

    .Adhesions-Intro {
        padding-top: 0;
    }

    .Adhesions-Text {
        display: inline-block;
    }

    .Adhesions-RightTrue {
        border-Left: 0;
        border-top: 1px solid #ef7e2d;
        padding-top: 1em;
    }

    .Adhesions-Right {
        padding-left: 0;
        padding-top: 1em;
    }

    .Adhesions-Left {
        padding-right: 2em;
        padding-bottom: 1em;
    }

    .Adhesions-LeftTrue {
        border-bottom: 1px solid #ef7e2d;
        border-right: 0;
    }
    
    .Adhesions-Text.Adhesions-LastText {
        margin-bottom: 0em;
    }
}
.MuscuSante-Core {
    
}

.MuscuSante {
    display: block;
    margin-bottom: 4em;
}   

.MuscuSante.MuscuSanteImg {
    margin: auto;
}

.MuscuSante.MuscuSanteImg img{
    max-width: 70%;
    border-radius: 10px;
}

.MuscuSante-SectionTitle {
    color:#ef7e2d;
    font-size: 1.3em;
    font-weight: bold;
    margin-bottom: 1em;
    display: inline-block;
}

.MuscuSante.MuscuSante-Intro {
    font-family: 'Geometria', sans-serif;
    font-size: 0.9em;
    vertical-align: top;
    position: relative;
}

.MuscuSante-Text {
    font-size: 1.1em;
    line-height: 1.5em;
}

.MuscuSante-highlight {
    color: #0076b1;
    font-weight: bold;
}

.MuscuSante-Title {
    font-size: 2em;
    margin-top: 0;
    margin-bottom: 1.5em;
    color: #ef7e2d;
}

.MuscuSante-Title::after {
    content: '';
    position: absolute;
    width: 50px;
    height: 1.5em;
    border-bottom: 2px solid #ef7e2d;
    left: 0;
}

#sport-sante ul li::marker, #sport-alimentation ul li::marker, #jardiner-sante ul li::marker{
    color: #ef7e2d;
}

.MuscuSante-Core img {
    max-width: 100%;
    border-radius: 10px;
    margin: 30px 0;
}

.MuscuSante-Twoimg, .MuscuSante-Twoimg-variante {
    display: flex;
    justify-content:space-evenly;
    flex-flow: wrap;
}

.MuscuSante-Twoimg > img {
    max-width: 40%;
    flex: 0 1 auto;
}

.MuscuSante-Twoimg-variante > img {
    max-width: 47%;
    max-height: 400px;
}

@media (max-width: 45.5em) {
    .MuscuSante-Core {
        flex-direction: column-reverse;
        margin-bottom: 0;
    }
    
    .MuscuSante-Title {
        margin-top: 0.5em;
        font-size: 1.7em
    }
}
.Accueil-Core {
    font-size: 1em;
    position: relative;
    font-family: 'Geometria', sans-serif;
}

.Accueil-Intro {
    font-size: 1.3em;
    padding-bottom: 0.5em;
    box-shadow: 0 10px 5px -11px rgb(0 0 0 / 30%);
    padding-top: 1.5em;
}

.Accueil-Core img {
    max-width: 100%;
    border-radius: 10px;
    margin: 30px 0;
}

.Accueil-Main-Picture img {
    max-width: 80%;
}

.Accueil-Main-Picture {
    text-align: center;
}

.Accueil-Pictures {
    display: flex;
}

.Accueil-Pictures span {
    max-width: 60%;
    flex: 0 1 auto;
    text-align: center;
    margin: auto;
    font-family: 'Perpetua';
}

.Accueil-Highlights div:nth-child(1),.Accueil-Highlights div:nth-child(2) {
    box-shadow: 0 10px 5px -11px rgb(0 0 0 / 30%);
    padding: 2em;
}

.Accueil-Pictures img {
   max-width: 40%;
}

.Accueil-Pictures span:last-child {
    max-width: 33%;
}   

.Accueil-Highlights {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding-bottom: 5em;
    max-width: 90%;
    margin: auto;
    text-align: center;
}

.Accueil-Highlights div {
    margin-bottom: 1em;
    width: 40%;
    font-size: 1em;
    padding-top: 1.75em;
    margin: auto;
}

.Accueil-Highlights div span {
    font-size: 1.5em;
    padding-bottom: 0.3em;
    display: inline-block;
    color: #ef7e2d;
}

.Accueil-Accroche {
    padding-bottom: 3em;
    width: 80%;
    margin: auto;
    text-align: center;
    font-size: 1.2em;
}

.Accueil-Highlights-Intro {
    color: #0076b1;
    box-shadow: 0 10px 5px -11px rgb(0 0 0 / 30%);
    font-size: 2em;
    text-align: center;
    margin: auto;
    width: 30%;
    padding-bottom: 0.5em;
}

@media (max-width: 45.5em) {
    .Accueil-Pictures {
        display: inline-block;
    }
    .Accueil-Pictures span {
        display: inline-block;
        max-width: 50%;
        padding: 0;
    }

    .Accueil-Pictures span img {
        max-width: 60%;
    }

    .Accueil-Pictures span:last-child {
        max-width: inherit;
    }   

    .Accueil-Pictures .Accueil-Intro {
        padding-bottom: 0.8em;
    }
}
