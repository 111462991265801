.Contact-Core {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3em;
}

.Contact {
    flex: 0 48%;
    text-align: left;
    position: relative;
}

.Contact-Intro {
    font-family: 'Geometria', sans-serif;
    font-size: 0.9em;
    vertical-align: top;
    position: relative;
}

.Contact-Title {
    font-size: 2em;
    margin-top: 0;
    margin-bottom: 1.5em;
    color: #ef7e2d;
}

.Contact-Title::after {
    content: '';
    position: absolute;
    width: 50px;
    height: 1.5em;
    border-bottom: 2px solid #ef7e2d;
    left: 0;
}

.Contact-Text {
    font-size: 0.9em;
}

.Contact.Contact-Form {
    font-family: 'Geometria', sans-serif;
    font-size: 0.9em;
    vertical-align: top;
}

.Contact-Telechargements li::marker{
    color: #0076b1;
}

.Contact ul {
    list-style-type: none;
    display: inline-block;
    padding: 0;
    margin: 0em 0;
}

.Contact ul.Contact-mss_corrdonnes li span {
    width: inherit;
    color: #0076b1;
}

.Contact ul.Contact-mss_corrdonnes {
    width: 50%;
    vertical-align: text-top;
}

.Contact ul.Contact-mss_corrdonnes li {
    margin-bottom: 1em;
    font-size: 0.8em;
}

.Contact ul li {
    line-height: 1.8em;
}

.Contact-sms {
    text-decoration: none;
    color: #333;
}

.Contact ul li span {
    width: 7em;
    display: inline-block;
    font-weight: bold;
}

#contactForm span {
    display: inline-block;
    width: 100%;
}

#contactForm .Button {
    border-radius: 5px;
    padding: 4px 10px;
    background-color: white;
}

#contactForm input, #contactForm textarea, #contactForm .Button {
    border: 1px solid #999;
}

.Contact.ContactImg img{
    max-width: 100%;
    border-radius: 10px 0;
}

#submit {
    cursor: pointer;
}

@media (min-device-width: 768px) and (max-device-width: 1024px) {
    .Contact {
        flex: 0 1 100%;
        text-align: left;
    }

    .Contact-Core {
        flex-wrap: wrap;
    }
}

@media (max-width: 45.5em) {
    .Contact-Core {
        flex-direction: column;
    }

    #contactForm input, textarea {
        width: 100%;
        padding: 0;
    }

    .Contact-Title {
        font-size: 1.7em;
    }

    #maps {
        margin-top: 1em;
    }

    .Contact ul.Contact-mss_corrdonnes {
        width: 100%;
    }
}