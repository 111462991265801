.MSS-Core {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3em;
}

.MSS {
    flex: 0 48%;
    text-align: left;
}   

.MSS.MSSImg img{
    max-width: 100%;
}

.MSS.MSSImgLeft img {
    border-radius: 0 10px;
}

.MSS.MSSImgRight img {
    border-radius: 10px 0;
}

.MSS.MSS-Intro {
    font-family: 'Geometria', sans-serif;
    font-size: 0.9em;
    vertical-align: top;
    position: relative;
}

.MSS-Text {
    font-size: 1.1em;
    line-height: 1.5em;
}

.MSS-Title {
    font-size: 2em;
    margin-top: 0;
    margin-bottom: 1.5em;
    color: #ef7e2d;
}

.MSS-ImgCentered {
    text-align: center;
}   

.MSS-ImgCentered img {
    width: 100%;
    border-radius: 10px;
}

.MSS-Title::after {
    content: '';
    position: absolute;
    width: 50px;
    height: 1.5em;
    border-bottom: 2px solid #ef7e2d;
    left: 0;
}

.MSS-SubTitle {
    color: #0076b1;
    font-weight: bold;
    font-family: 'Geometria', sans-serif;
    font-size: 14px;
    margin-top:3em;
    margin-bottom: 0.5em;
    padding: 0;
}

.MSS-SubTitle + p {
    font-family: 'Geometria', sans-serif;
    font-size: 12px;
    padding: 0;
    margin: 0;
    line-height: 1.5em;
}

#team {
    margin-bottom: 0;
    padding-bottom: 0;
}

.MSS-Intro + .MSS-SubTitle {
    margin-top: 0;
}

@media (max-width: 45.5em) {
    .MSS-Core {
        flex-direction: column-reverse;
        margin-bottom: 0;
    }
    
    .MSS-Title {
        margin-top: 0.5em;
        font-size: 1.7em;
    }

    #maison + .MSSImgRight {
        margin-top: 1em;
    }
}